import { Mail, Linkedin } from 'lucide-react'
import { Link } from 'react-router'
import { ROUTE_PATHS } from '#app/const/routes.ts'
import { bodySize, empiriumYearFooter } from '#app/utils/layout.ts'

// TODO: Add sections here once we allow them
// const footerSections = {
// Company: [
// 	{ title: 'About Us', link: '#' },
// 	{
// 		title: 'Careers',
// 		link: '#',
// 	},
// 	// {
// 	// 	title: 'Blog',
// 	// 	link: '#',
// 	// },
// 	// {
// 	// 	title: 'Press',
// 	// 	link: '#',
// 	// },
// ],
// Resources: [
// 	{
// 		title: 'Support Center',
// 		link: '#',
// 	},
// 	{
// 		title: 'Documentation',
// 		link: '#',
// 	},
// 	{
// 		title: 'FAQs',
// 		link: '#',
// 	},
// 	{
// 		title: 'Security',
// 		link: '#',
// 	},
// ],
// }

const footerContact = {
	Contact: [
		// {
		// 	title: 'United States',
		// 	link: undefined,
		// 	icon: <MapPin className="text-empirium-teal mr-3 mt-0.5 h-5 w-5" />,
		// },
		{
			title: 'info@empirium.co',
			link: 'mailto:info@empirium.co',
			icon: <Mail className="mr-3 mt-0.5 h-5 w-5 text-empirium-teal" />,
		},
		// {
		// 	title: 'empirium.co',
		// 	link: '#',
		// 	icon: <Globe className="text-empirium-teal mr-3 mt-0.5 h-5 w-5" />,
		// },
	],
}

export const Footer = () => {
	return (
		<footer
			className={bodySize({
				class: 'bg-empirium-dark pb-2 pt-12 text-white',
				fullSize: true,
			})}
		>
			<div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
					<div>
						<h3 className="mb-4 text-xl font-bold">EMPIRIUM</h3>
						<p className="mb-4 text-gray-400">
							The global marketplace for critical supply.
						</p>
						<div className="flex items-center space-x-4">
							<Link
								to="https://www.linkedin.com/company/empirium-inc/"
								className="text-white transition-colors hover:text-empirium-teal"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Linkedin className="size-6" />
							</Link>
						</div>
					</div>
					{/* TODO: Re-enable this when we add sections */}
					{/* {Object.keys(footerSections).map((k) => (
						<div key={k}>
							<h3 className="mb-4 text-lg font-semibold">{k}</h3>
							<ul className="space-y-2">
								{footerSections[k as keyof typeof footerSections].map((s) => (
									<li key={s.title}>
										<Link
											to={s.link}
											className="hover:text-empirium-teal text-gray-400 transition-colors"
										>
											{s.title}
										</Link>
									</li>
								))}
							</ul>
						</div>
					))} */}
					{Object.keys(footerContact).map((c) => (
						<div key={c} className="lg:order-4">
							<h3 className="mb-4 text-lg font-semibold">{c}</h3>
							<div className="space-y-4">
								{footerContact[c as keyof typeof footerContact].map(
									(content) => (
										<div key={content.title} className="flex items-start">
											{content.icon}
											{content.link ? (
												<Link
													to={content.link}
													className="text-gray-400 transition-colors hover:text-empirium-teal"
												>
													{content.title}
												</Link>
											) : (
												<p className="text-gray-400">{content.title}</p>
											)}
										</div>
									),
								)}
							</div>
						</div>
					))}
				</div>

				<div className="mt-12 flex flex-col items-center justify-between border-t border-gray-800 pt-2 md:flex-row">
					<p className="mb-4 text-sm text-gray-500 md:mb-0">
						&copy; {empiriumYearFooter}. All rights reserved.
					</p>
					<div className="flex space-x-6">
						<Link
							to={ROUTE_PATHS.PRIVACY_POLICY}
							className="text-sm text-gray-500 transition-colors hover:text-empirium-teal"
						>
							Privacy Policy
						</Link>
						<Link
							to={ROUTE_PATHS.TERMS_OF_SERVICE}
							className="text-sm text-gray-500 transition-colors hover:text-empirium-teal"
						>
							Terms of Service
						</Link>
					</div>
				</div>
			</div>
		</footer>
	)
}
