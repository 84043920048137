import { useEffect } from 'react'
import { Outlet } from 'react-router'
import { Footer } from './__components/footer'
import { Header } from './__components/header'

export default () => {
	// Smooth scroll behavior for anchor links
	useEffect(() => {
		const handleAnchorClick = (e: MouseEvent) => {
			const target = e.target as HTMLElement
			if (
				target.tagName === 'A' &&
				target.getAttribute('href')?.startsWith('#')
			) {
				e.preventDefault()
				const id = target.getAttribute('href')?.substring(1)
				const element = document.getElementById(id || '')
				if (element) {
					window.scrollTo({
						top: element.offsetTop - 80,
						behavior: 'smooth',
					})
				}
			}
		}

		document.addEventListener('click', handleAnchorClick)
		return () => document.removeEventListener('click', handleAnchorClick)
	}, [])

	return (
		<div className="min-h-screen">
			<Header />
			<main>
				<Outlet />
			</main>
			<Footer />
		</div>
	)
}
