import { MenuIcon, Sparkles, X } from 'lucide-react'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router'
import { LogoIcon } from '#app/assets/logoIcon.tsx'
import { Badge } from '#app/components/ui/badge.tsx'
import { Button } from '#app/components/ui/button'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '#app/components/ui/popover.tsx'
import { ROUTE_PATHS } from '#app/const/routes.ts'
import { bodySize } from '#app/utils/layout.ts'
import { JoinWaitlist } from './join-waitlist'

export const Header = () => {
	const { pathname, search } = useLocation()
	const isHomePage = pathname === ROUTE_PATHS.MARKETING
	const defaultOpen =
		new URLSearchParams(search).get('join-waitlist') === 'true'

	const headerOptions = [
		{
			title: 'Why Empirium?',
			link: isHomePage ? '#features' : ROUTE_PATHS.MARKETING + '#features',
		},
		{
			title: 'Marketplace',
			link: isHomePage ? '#solutions' : ROUTE_PATHS.MARKETING + '#solutions',
			comingSoon: true,
		},
		// TODO: Add this back in once we have testimonials
		// {
		// 	title: 'Testimonials',
		// 	link: isHomePage
		// 		? '#testimonials'
		// 		: ROUTE_PATHS.MARKETING + '#testimonials',
		// },
		{
			title: 'Contact',
			link: isHomePage ? '#contact' : ROUTE_PATHS.MARKETING + '#contact',
		},
	]

	const [isScrolled, setIsScrolled] = useState(false)
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 10) {
				setIsScrolled(true)
			} else {
				setIsScrolled(false)
			}
		}
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])
	return (
		<header
			className={bodySize({
				fullSize: true,
				class: `transition-custom fixed left-0 right-0 top-0 z-50 ${isScrolled ? 'bg-white/90 py-2 shadow-sm backdrop-blur-md' : 'bg-transparent py-4'}`,
			})}
		>
			<div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						<Link to="/" className="flex items-center gap-2">
							<LogoIcon className="size-8 text-empirium-teal" />
							<span className="font-display text-2xl font-bold text-empirium-dark"></span>
						</Link>
					</div>

					{/* Desktop Navigation */}
					<nav className="hidden items-center space-x-8 md:flex">
						{headerOptions.map((h) =>
							h.comingSoon ? (
								<Popover key={h.title}>
									<PopoverTrigger className="flex font-medium text-gray-600 transition-colors hover:text-empirium-teal">
										{h.title}
										<Sparkles className="size-3" />
									</PopoverTrigger>
									<PopoverContent side="bottom" className="w-fit p-2 text-sm">
										Coming soon!
									</PopoverContent>
								</Popover>
							) : (
								<Link
									key={h.title}
									to={h.link}
									className={`font-medium text-gray-600 transition-colors hover:text-empirium-teal`}
								>
									{h.title}
								</Link>
							),
						)}
						<JoinWaitlist intent="join-waitlist" defaultOpen={defaultOpen} />
					</nav>

					{/* Mobile Menu Button */}
					<Button
						className="rounded-md bg-transparent p-2 focus:outline-none md:hidden"
						variant="outline"
						onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
						aria-label="Toggle menu"
					>
						{isMobileMenuOpen ? (
							<X className="h-6 w-6 text-empirium-dark" />
						) : (
							<MenuIcon className="h-6 w-6 text-empirium-dark" />
						)}
					</Button>
				</div>

				{/* Mobile Navigation */}
				{isMobileMenuOpen && (
					<div className="glassmorphism absolute left-4 right-4 mt-2 origin-top animate-scale-in px-4 py-4 md:hidden">
						<nav className="flex flex-col space-y-4">
							{headerOptions.map((h) => (
								<Link
									key={h.title}
									to={h.link}
									className={`flex gap-x-2 p-2 font-medium text-gray-600 transition-colors hover:text-empirium-teal ${h.comingSoon ? 'pointer-events-none' : ''}`}
									onClick={() => setIsMobileMenuOpen(false)}
								>
									{h.title}
									{h.comingSoon ? (
										<Badge className="flex w-fit gap-1 text-nowrap border border-empirium-teal bg-white text-xxs text-empirium-teal hover:bg-white">
											<Sparkles className="size-2.5" />
											Coming Soon!
										</Badge>
									) : undefined}
								</Link>
							))}
							<JoinWaitlist intent="join-waitlist" defaultOpen={defaultOpen} />
						</nav>
					</div>
				)}
			</div>
		</header>
	)
}
